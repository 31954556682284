<template>
	<div>
		<!-- Title -->
		<b-card-title class="text-primary mb-3">Sign In</b-card-title>

		<authenticate v-if="mobile" :mobile="mobile" @success="successAuth" @forgotClick="forgotClick" />
	</div>
</template>

<script>
import { mapActions } from "vuex";

import { userForgotPassword } from "../../apis";
import Authenticate from "../../components/auth/Authenticate.vue";

export default {
	components: { Authenticate },
	created() {
		if (!this.mobile) {
			// no mobile no. found in route params
			this.$router.replace({ name: "Home" });
			return;
		}
	},
	data() {
		return {
			query: this.$route.query,
			params: this.$route.params,
		};
	},
	computed: {
		mobile() {
			return this.params.mobile;
		},
	},
	methods: {
		...mapActions({
			saveToken: "login/saveToken",
		}),
		successAuth(data) {
			//save token
			this.saveToken(data.token).then(() => {
				// redirect to place order screen
				let redirect = this.query.redirect;
				if (!redirect) redirect = "Home";
				this.$router.replace({
					name: redirect,
					params: { ...this.params, ...this.query },
					query: this.query,
				});
				return;
			});
		},

		forgotClick() {
			userForgotPassword(this.mobile).then((data) => {
				// redirect to reset password page
				this.$router.push({
					name: "ResetPassword",
					params: { otpData: { ...data }, ...this.params },
					query: this.query,
				});
			});
		},
	},
};
</script>

<style></style>
